<template>
  <div class='flex flex-col-reverse items-stretch lg:flex-row min-h-screen'>
    <div
      class='w-full lg:w-96 px-4 lg:px-16 py-16 text-gray-50 flex flex-col items-start justify-start'
      :style='backgroundStyle'>
      <h1 class='text-3xl font-light'>사전등록</h1>
    </div>
    <div class='p-8 lg:p-16 overflow-y-auto h-screen flex-grow'>
      <div class='max-w-2xl'>
        <div class='mb-12'>
          <img 
            :src='eventConfigLogoImageUrl'
            style='max-height: 5rem;' />
        </div>
        <div class='px-12 py-8 rounded' style='background-color: #FAFAFA;'>
          <h2 
            class='text-xl font-semibold text-center'>
            사전등록이 완료되었습니다!
          </h2>
        </div>
        <router-link :to='{name: "Main"}' class='block mt-8 w-48 text-center bg-gray-900 text-white py-3 px-2 rounded-md hover:shadow-lg text-sm'>웨비나 보러가기</router-link>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SignupConfirmation',
  computed: {
    ...mapState('users', [
      'profile'
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
      'themeTextColorStyle',
      'eventConfigLogoImageUrl',
    ]),
    backgroundStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>
